<template>
  <div class="flex-1 flex flex-col max-h-full">
    <sub-header
      :handleSearchFilter="handleSearchFilter"
      :showBtn="true"
      :handleClick="showCheckModal"
      buttonText="Create Check"
    />
    <div
      class="flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar"
    >
      <Table
        :columns="columnOption"
        :key="currentPageData.total"
        :rows="checks"
        @handleClone="handleCloneCheck($event)"
        :isLoading="isLoading"
        :filters="serverParams"
        :filterOptions="columnFilterOptions"
        :filterOptionsAsync="columnFilterOptionsAsync"
        @per-page-change="onPageChange($event, true)"
        @page-change="onPageChange($event)"
        @column-filter="onColumnFilters($event)"
        :paginationOptions="paginationOptions"
        :totalRows="pageData.total"
        @filters-clear="clearFilters"
        @search-filter="getSearchOptionsTick($event)"
      >
        <template #link="customData">
          <div>
            <router-link
              :to="{
                name: 'check admin details',
                params: { id: customData.customData.id },
              }"
              class="text-primary whitespace-nowrap cursor-pointer hover:text-primary-300"
              >{{ customData.customData.name || "-" }}
            </router-link>
          </div>
        </template>
        <template #source="customData">
          <span
            class="inline-flex rounded-sm px-2 text-xs font-semibold leading-5 truncate"
            :class="
              customData.customData.is_custom
                ? 'bg-green-100 text-green-800'
                : 'bg-blue-200 text-blue-800'
            "
            >{{ isRestrictedOrCustom(customData.customData) }}</span
          >
        </template>
        <template #show_info="customData">
          <p
            :class="
              customData.customData && customData.customData.in_packages > 0
                ? 'text-primary hover:text-primary-300 hover:underline cursor-pointer'
                : 'text-gray-500'
            "
            @click.stop="setCheckPackages(customData.customData)"
          >
            used in {{ customData.customData.in_packages }} packages
          </p>
        </template>
      </Table>
    </div>

    <!-- Create Check Modal -->
    <modal-content
      ref="check-modal"
      :max-width="480"
      name="check-modal"
      title="Add New Check"
      @close="closeCheckModal"
      :showFooter="false"
    >
      <template #content>
        <FormulateForm #default="{ hasErrors }" class="min-h-full flex-1">
          <div class="flex flex-col justify-between min-h-full">
            <div class="form flex w-full flex-col flex-1">
              <div class="flex flex-col mb-4">
                <span class="text-gray-6 inline-block py-2"
                  >Check Category
                  <span class="text-red-500 ml-1 text-lg mr-auto"> * </span>
                </span>
                <Dropdown
                  reposition
                  searchable
                  placeholder="choose check category"
                  :options="checkCategories"
                  :colors="{
                    text: '#282828',
                    bg: 'white',
                    svg: '#282828',
                    border: '#bcbcbc',
                  }"
                  :config="{ label: 'name', trackBy: 'id' }"
                  :value="checkData.checkCategory"
                  @input="(value) => updateCheckCategory(value)"
                  :limit="1"
                  minWidth="102px"
                  :loading="checkTypeFetching"
                />
              </div>
              <div class="flex flex-col mb-4">
                <span class="text-gray-6 inline-block py-2"
                  >Check Type
                  <span class="text-red-500 ml-1 text-lg mr-auto"> * </span>
                </span>
                <Dropdown
                  reposition
                  searchable
                  placeholder="Add this as new check type"
                  :disabled="!checkData.checkCategory"
                  :options="checkTypes"
                  :colors="{
                    text: '#282828',
                    bg: 'white',
                    svg: '#282828',
                    border: '#bcbcbc',
                  }"
                  :config="{ label: 'name', trackBy: 'id' }"
                  :value="checkData.checkType"
                  @input="(value) => updateCheckType(value)"
                  :limit="1"
                  minWidth="102px"
                  :loading="checkTypeFetching"
                />
              </div>
              <FormulateInput
                class="w-full"
                label="Check Name"
                placeholder="Check Name"
                type="text"
                validation="required"
                :element-class="
                  (context, classes) => ['flex-1 min-w-full'].concat(classes)
                "
                :disabled="!checkData.checkCategory"
                v-model="checkData.check_name"
              />
              <FormulateInput
                class="w-full"
                label="Check Label"
                placeholder="Check Label"
                type="text"
                validation="required"
                :element-class="
                  (context, classes) => ['flex-1 min-w-full'].concat(classes)
                "
                :disabled="!checkData.checkCategory"
                v-model="checkData.label"
              />
              <FormulateInput
                v-model="checkData.entity_types"
                validation="required"
                class="w-full"
                label="Entity Type"
                placeholder="Select Entity Type"
                type="multi-select"
                variant="alt"
                :element-class="
                  (context, classes) => ['flex-1 min-w-full'].concat(classes)
                "
                :options="entityTypeOptions"
                :loading="isEntityTypesLoading"
                :config="{
                  ...ENTITY_TYPES_DROPDOWN_CONFIG,
                  limit: 4,
                }"
                :disabled="!checkData.checkCategory"
              />
              <FormulateInput
                class="w-full"
                label="Check Description"
                placeholder="Check Description"
                type="textarea"
                validation="required"
                :element-class="
                  (context, classes) => ['flex-1 min-w-full'].concat(classes)
                "
                :disabled="!checkData.checkCategory"
                v-model="checkData.description"
              />
              <FormulateInput
                class="w-full"
                label="Sla Period (days)"
                placeholder="Sla Period (days)"
                type="text"
                min="1"
                validation="required|number|min:1,number"
                :element-class="
                  (context, classes) => ['flex-1 min-w-full'].concat(classes)
                "
                :disabled="!checkData.checkCategory"
                v-model="checkData.sla"
              />
            </div>
            <div class="card-actions justify-end">
              <Button
                :loader="createCheckLoading"
                text="Create Check"
                type="primary"
                :disabled="
                  hasErrors ||
                    createCheckLoading ||
                    !checkData.checkCategory ||
                    !checkData.checkType
                "
                @click="handleCreateCheck()"
              />
            </div>
          </div>
        </FormulateForm>
      </template>
      <template #footer>
        <div></div>
      </template>
    </modal-content>
    <!-- End Create Check Modal -->

    <CheckPackagesModal
      @closed="setCheckPackages(null)"
      @handleFilterResult="handleFilterResult($event, 'package')"
      :checkData="checkPackages"
      :packages="getCheckPackages"
      :isLoading="fetchingCheckPackages"
    />
  </div>
</template>
<script>
import SubHeader from "@/components/SubHeader";
import Table from "@shared/dashboard-components/table-large";
import axios from "@/axios";
import { debounce } from "lodash";
import modalContent from "@/components/modal-content";
// import Input from "@/components/input";
import Button from "@/components/button";
// import { uuid } from "vue-uuid";
import { onlyUnique } from "@/plugins/utils.js";
import { uuid } from "vue-uuid";
import Dropdown from "@/components/dropdown-base";
import CheckPackagesModal from "./check-dragable-section/components/checkPackagesModal.vue";
import { mapActions, mapState } from "vuex";
import { ENTITY_TYPES_DROPDOWN_CONFIG } from "@shared/utils/constants";
import VueCookies from "vue-cookies";

export default {
  name: "check-admin",
  title: "Checks",

  components: {
    SubHeader,
    Table,
    modalContent,
    //   Input,
    Button,
    Dropdown,
    CheckPackagesModal,
  },
  props: {},
  data: () => ({
    ENTITY_TYPES_DROPDOWN_CONFIG,
    searchTimeout: null,
    searchLoading: {},
    columnFilterOptionsAsync: null,
    isEntityTypesLoading: false,
    columns: [
      {
        label: "Name",
        field: "name",
        config: { filter: true, type: "link" },
        tdClass: "truncate max-w-sm",
      },
      {
        label: "Label",
        field: "label",
        config: { filter: true, type: "select" },
        tdClass: "truncate max-w-sm",
      },
      {
        label: "Check Type",
        field: "category",
        query_key: "check_type",
        config: { filter: true, type: "select" },
        tdClass: "truncate max-w-sm",
      },
      {
        label: "Source",
        field: "is_custom",
        query_key: "source",
        prefetchedOptions: [
          { name: "Custom", value: "custom" },
          { name: "System Provided", value: "system_provided" },
        ],
        config: { filter: true, type: "source" },
        tdClass: "truncate max-w-sm",
      },
      {
        label: "Packages",
        field: "in_packages",
        config: { filter: false, type: "show_info" },
        tdClass: "truncate max-w-sm",
      },
      {
        label: "SLA (in Days)",
        field: "sla",
        config: { filter: false },
        tdClass: "truncate max-w-sm",
      },
      {
        label: "Clone",
        field: "clone",
        config: {
          type: "clone",
          filter: false,
        },
      },
      // {"label":"Automatic/Manual","field":"is_automatic"},
    ],
    serverParams: null,
    checks: [],
    isLoading: false,
    isCloning: false,
    searchValue: "",
    createCheckLoading: false,
    checkData: {
      check_name: "",
      description: "",
      sla: 1,
      checkType: null,
      checkCategory: null,
      entity_types: [],
    },
    checkTypeFetching: false,
    checkTypes: [],
    checkCategories: [],
    checkPackages: null,
    paginationOptions: {
      enabled: true,
      mode: "remote",
    },
    pageData: {
      total: 0,
    },
    currentPageData: {
      page: 1,
      count: 10,
    },
    fetchingCheckPackages: false,
    checksPackages: [],
    searchParams: {
      package: "",
    },
  }),

  created() {},
  computed: {
    ...mapState({
      entityTypeOptions: "entityTypeList",
    }),
    columnFilterOptions() {
      let cols = this.getFilterOptions(this.columns, this.checks);
      return cols;
    },
    columnOption() {
      return this.columns.map((el) => ({
        ...el,
        config: {
          ...el.config,
          isLoading: !!this.searchLoading[el.field] || false,
        },
      }));
    },
    getCheckPackages() {
      return this.checksPackages?.filter((el) =>
        el?.name
          ?.toLowerCase()
          .includes(this.searchParams?.package?.toLowerCase())
      );
    },
  },

  async mounted() {
    if (!this.$store.getters.getTenantId) {
      await this.$store.dispatch("fetchTenantId");
    }
    await this.fetchChecksList();
  },

  methods: {
    ...mapActions(["fetchEntityTypeList"]),
    currentPageDataOpt() {
      return { ...this.currentPageData, perPage: this.currentPageData.count };
    },
    closeCheckModal() {
      this.$refs["check-modal"].hideModal();
    },
    updateCheckCategory(value) {
      this.checkData.checkCategory = value;
    },
    updateCheckType(value) {
      this.checkData.checkType = value;
    },
    async showCheckModal() {
      this.$refs["check-modal"].showModal();
      this.checkData = {
        check_name: "",
        description: "",
        sla: 1,
        checkType: null,
        checkCategory: null,
      };
      await this.fetchCheckTypes();
      await this.getSubCheckTypes();
      await this.fetchEntityTypeOptions();
    },
    async fetchEntityTypeOptions() {
      this.isEntityTypesLoading = true;
      if (!this.entityTypeOptions.length) {
        await this.fetchEntityTypeList();
      }
      this.isEntityTypesLoading = false;
    },
    // get checks list
    async fetchChecksList() {
      this.isLoading = true;
      const tenant = this.$store.getters.getTenantId;
      let qParams = new URLSearchParams(this.currentPageData);
      let filterParams = {};

      const getFilterValue = (key) => {
        if (["source"].includes(key)) {
          return this.serverParams[key]["value"];
        } else return this.serverParams[key]["name"];
      };
      if (this.serverParams) {
        Object.keys(this.serverParams).map((key) => {
          // if that key exist
          // add it to filter else we'll delete it
          // so it wont make empty strings in parameters
          filterParams[key] = this.serverParams[key]
            ? getFilterValue(key)
            : delete filterParams[key];
        });
      }
      let url = `/tenant-check/${tenant}?${qParams}`;
      let { data } = await axios.get(url, {
        params: {
          ...filterParams,
        },
      });
      try {
        let checksData = data?.data?.map((el) => {
          if (el.parameters && el.parameters !== "null") {
            let data = JSON.parse(el.parameters);
            return { ...el, parameters: data.parameters };
          } else {
            return { ...el };
          }
        });
        this.checks = checksData;
        this.pageData = data?.page;
      } catch (error) {
        console.log(error, "Error");
      }
      // this.columns = columns;
      this.isLoading = false;
    },
    // end checks list

    // fetch all check types

    async fetchCheckTypes() {
      this.checkTypeFetching = true;
      try {
        let url = `/tenant-check/type/checks/admin`;
        let token = VueCookies.get("token");
        let { data } = await axios.get(url, {headers: {
          'Authorization':`Bearer ${token}`
        }});
        this.checkCategories = data.check_types;
      } catch (error) {
        console.log(error, "Error");
      }
      this.checkTypeFetching = false;
    },

    // end fetch check types

    handleRowClick(data) {
      this.$router.push({
        name: "check admin details",
        params: { id: data.id },
      });
    },

    // Create Check

    async handleCreateCheck() {
      this.createCheckLoading = true;
      let url = `/tenant-check/custom-check`;
      if (this.checkData.sla > 0) {
        let payload = {
          tenant_id: this.$store.getters.getTenantId,
          check_type_id: this.checkData.checkCategory.id,
          check_name: this.checkData.check_name,
          description: this.checkData.description,
          sla: this.checkData.sla,
          check_sub_type_id: this.checkData.checkType.id,
          entity_types: this.checkData.entity_types.map((el) => ({
            entity_type_id: el.id,
          })),
          label: this.checkData.label,
        };
        try {
          let { data } = await axios.post(url, payload);
          let checkData = {
            description: payload.description,
            id: data.check_id.id,
            is_custom: true,
            name: payload.check_name,
            parameters: null,
            sla: payload.sla,
            tenant_id: this.$store.getters.getTenantId,
          };
          this.checks.push(checkData);
          console.log(checkData);
          this.$router.push({
            name: "check admin details",
            params: { id: checkData.id },
          });
          this.$toast.success(data.message);
          this.closeCheckModal();
        } catch (error) {
          this.createCheckLoading = false;
          this.$toast.warning(
            error.response.data.detail || "Failed to create new check"
          );
          this.closeCheckModal();
        }
      } else {
        this.createCheckLoading = false;
        return this.$toast.warning("SLA Period should be greated than 0");
      }
      this.createCheckLoading = false;
    },

    // End Create Check

    // clone check

    async handleCloneCheck(check) {
      console.log("check :>> ", check);
      // this.isCloning = true
      let url = `/tenant-check/${check.id}/clone`;
      let payload = {
        tenant_id: this.$store.getters.getTenantId,
        check_name: check.name,
      };
      try {
        let { data } = await axios.post(url, payload);
        this.$router.push({
          name: "check admin details",
          params: { id: data.check_id },
        });
        this.$toast.success(data.message);
      } catch (error) {
        this.$toast.error(
          error.response.data.detail || "Unable to clone check admin"
        );
      }
    },
    handleSearchFilter: debounce(function(value) {
      this.searchValue = value;
    }, 1000),

    // end Clone Check

    // addCustomCheckType (newTag) {
    //     let custom_type ={
    //         id: uuid.v4(),
    //         name: newTag,
    //         custom: true
    //     }
    //     this.checkTypes.push(custom_type),
    //     this.checkData.checkType = custom_type
    // },

    // Get subcategory lists
    async getSubCheckTypes() {
      this.checkTypeFetching = true;
      try {
        let url = `/tenant-check/type/checks/sub-types`;
        let token = VueCookies.get("token");
        let { data } = await axios.get(url, {headers: {
          'Authorization':`Bearer ${token}`
        }});
        this.checkTypes = data;
      } catch (error) {
        console.log(error, "Error");
      }
      this.checkTypeFetching = false;
    },
    async setCheckPackages(payload) {
      this.checksPackages = [];
      if (payload && payload.in_packages > 0) {
        this.$modal.show("checkPackagesModal");
        this.checkPackages = payload;
        this.fetchingCheckPackages = true;
        const { data: result } = await axios.get(
          `tenant-check/${payload.tenant_id}/${payload.id}/packages`
        );
        this.checksPackages = result.packages;
        this.fetchingCheckPackages = false;
      }
    },
    isRestrictedOrCustom(check) {
      if (check.is_custom) {
        return "Custom";
      } else if (check.is_restricted) {
        return "Restricted";
      } else {
        return "System Provided";
      }
    },
    async onPageChange(data, resetPage = false) {
      this.currentPageData = {
        page: data.currentPage,
        count: data.currentPerPage,
      };
      if (resetPage) this.currentPageData.page = 1;
      await this.fetchChecksList();
    },

    getSearchOptionsTick(payload) {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      const delay = 500;
      this.searchTimeout = setTimeout(() => {
        this.getSearchOptions(payload);
      }, delay);
    },

    async getSearchOptions(payload) {
      console.log("payload :>> ", payload);
      if (!payload.value) this.columnFilterOptionsAsync = null;
      else {
        this.$set(this.searchLoading, payload.column.field, true);
        try {
          const requestData = {
            ...this.serverParams,
            [payload.column.query_key || payload.column.field]: payload.value,
          };
          const { data } = await axios.get(
            `/tenant-check/${this.$store.getters.getTenantId}`,
            {
              params: requestData,
            }
          );
          this.columnFilterOptionsAsync = this.getFilterOptions(
            this.columns,
            data.data
          );
        } catch (error) {
          console.log("getSearchOptions :>> ", error);
        }

        this.$set(this.searchLoading, payload.column.field, false);
      }
    },

    async onColumnFilters({ columnFilters }) {
      this.currentPageData.page = 1;
      this.serverParams = columnFilters ? { ...columnFilters } : null;
      await this.fetchChecksList();
    },

    getFilterOptions(columns, rows) {
      return columns.reduce((result, curr) => {
        result[curr.field] = rows
          .filter((row) => row[curr.field])
          .map((row) => row[curr.field])
          .filter(onlyUnique)
          .map((el) => ({ id: uuid.v4(), name: el }));
        return result;
      }, {});
    },

    async clearFilters() {
      this.serverParams = null;
      await this.fetchChecksList();
    },
    handleFilterResult(data) {
      this.searchParams.package = data;
    },
  },
};
</script>

<style lang="scss">
.tooltip-text {
  display: none;
  position: absolute;
  z-index: 999 !important;
  width: auto;
  color: white;
  font-size: 12px;
  background-color: #192733;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
}

.hover-text:hover .tooltip-text {
  display: block;
}

#right {
  left: 70px;
  top: -30px;
}

// .modalContent .modalContent__footer{
//     display:none !important;
// }

// #bottom {
//   top: 25px;
//   left: -50%;
// }

// #bottom::before {
//   top: -5%;
//   left: 45%;
// }

// #left {
//   top: -8px;
//   right: 120%;
// }

// #left::before {
//   top: 35%;
//   left: 94%;
// }

// #right {
//   top: -8px;
//   left: 120%;
// }

// #right::before {
//   top: 35%;
//   left: -2%;
// }

.hover-text {
  position: relative;
}
</style>
